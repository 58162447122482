import Axios from "axios";
import systemUserClient from "~/integrations/self-service-system-adapter";
import telenorid from "~/telenorid/telenorid";

import https from "https";

const publicConfig = {};

const axiosConfig = {
	baseURL: "https://localhost:8080",
};

if (process.env.HTTPS && import.meta.server) {
	axiosConfig.httpsAgent = new https.Agent({
		rejectUnauthorized: false,
	});
}

const DISABLE_CACHE = {
	cache: { override: true },
};

const axios = Axios.create(axiosConfig);

axios.interceptors.response.use((res) => res.data);

// Append internal API URL to relative environment/browser path
const systemClient = systemUserClient("/ecommerce-products/v2");

// Append internal API URL to relative environment/browser path
const getBestClient = async () => {
	if (import.meta.client) {
		if (window.location.hostname === "localhost" && publicConfig.useLocalEcommerceApi === true) return axios;
		try {
			const user = await telenorid.getOrLoginUser();
			if (user && user.firstName !== "Gizmo") return systemClient;
		} catch (err) {
			console.log(err);
		}
	} else {
		if (process.env.NODE_ENV === "development" && publicConfig.useLocalEcommerceApi === true) return axios;
	}

	return systemClient;
};

const translateContext = (context) => {
	if (context === "privat" || context === "bedrift") return context;
	else return context === "business" ? "bedrift" : "privat";
};

export default {
	getInventoriesByIds: async (ids) => {
		const client = await getBestClient();

		return client.get(`products/${ids}/inventories/?context=privat`);
	},
	getProducts: async (term, context, size) => {
		if (translateContext(context) === "bedrift") return { products: [], total: 0 };
		const client = await getBestClient();
		try {
			let url = `/products/?q=${term}&unique=true&context=${translateContext(context)}`;
			if (size) url += `&size=${size}`;
			return await client.get(url);
		} catch (err) {
			console.log(err);
			return { products: [], total: 0 };
		}

		// return client.get(`/${context}/products/?q=${term}`)
	},
	getProductById: async (id, context) => {
		const client = await getBestClient();

		return client.get(`/products/${id}?context=${translateContext(context)}`);
	},
	getClickAndCollectStatus: async (id, context) => {
		const client = await getBestClient();

		return client.get(`/products/${id}/click-and-collect?context=${translateContext(context)}`);
	},
	getStoresWithProduct: async (id, context, latitude, longitude) => {
		const client = await getBestClient();

		if (latitude && longitude)
			return client.get(
				`/products/${id}/click-and-collect/stores?latitude=${latitude}&longitude=${longitude}&context=${translateContext(
					context,
				)}`,
			);

		return client.get(`/products/${id}/click-and-collect/stores?context=${translateContext(context)}`);
	},
	searchStoresWithProduct: async (id, context, term) => {
		const client = await getBestClient();

		return client.get(`/products/${id}/click-and-collect/stores?term=${term}&context=${translateContext(context)}`);
	},
	getProductByUrl: async (term, context) => {
		const client = await getBestClient();

		return client.get(`/products/url/?url=${term}&context=${translateContext(context)}`);
	},
	search: async (query, context) => {
		const client = await getBestClient();

		return client.post(`/products/query?context=${translateContext(context)}`, query);
	},
	getCategories: async (context) => {
		const client = await getBestClient();

		return client.get(`/categories?context=${translateContext(context)}`);
	},
	getFilters: async (context, categorynames, brand, query, subcategories, additionalfilters, device, priceModel) => {
		const client = await getBestClient();

		return client
			.get(
				`/filters?categorynames=${categorynames || ""}&brand=${brand || ""}&q=${query || ""}&context=${translateContext(
					context,
				)}&subcategories=${subcategories}&additionalfilters=${additionalfilters}&device=${device || ""}&priceModel=${
					priceModel || ""
				}`,
			)
			.catch((err) => console.error(err));
	},
	getSubcategories: async (context, category, brand, query, device, priceModel) => {
		const client = await getBestClient();

		return client.get(
			`/filters/subcategories?category=${category || ""}&brand=${brand || ""}&q=${query || ""}&device=${
				device || ""
			}&context=${translateContext(context)}&priceModel=${priceModel || ""}`,
		);
	},
	getMobileSubscriptionPrice: async () => {
		const client = await getBestClient();

		return client.get("/mobile-subscription");
	},

	getFilteredProducts: async (
		context,
		filters,
		subcategories,
		size,
		from = 0,
		sort,
		query,
		productsToPrioritize,
		onlyShowSwap,
		onlyPrioritized,
		device,
		priceModel,
	) => {
		const client = await getBestClient();

		let categories;
		let brands;
		let additionalfilters;

		if (filters) {
			categories = filters
				.filter((f) => f.startsWith("Categorynames:"))
				.map((f) => f.split(":").pop())
				.flat();
			brands = filters
				.filter((f) => f.startsWith("Brand:"))
				.map((f) => f.split(":").pop())
				.flat();
			additionalfilters = filters
				.filter((x) => x.startsWith("Additionalfilters:"))
				.map((f) => f.split(":").pop())
				.flat();
		}

		const options = {
			params: {
				prioritizeIds: productsToPrioritize,
				onlyShowSwap,
				onlyPrioritized,
				priceModel,
			},
		};

		return client.get(
			`/products/?categorynames=${categories}&subcategories=${subcategories}&brand=${brands}&additionalfilters=${additionalfilters}&device=${device}&size=${size}&from=${from}&sort=${sort}&unique=true&q=${
				query || ""
			}&context=${translateContext(context)}`,
			options,
		);
	},
	getAccessoriesForProduct: async (id, context) => {
		const client = await getBestClient();
		return client.get(`/products/${id}/accessories?context=${translateContext(context)}`);
	},
	getRecommendedAccessoriesForProduct: async (id, context) => {
		const client = await getBestClient();
		return client.get(`/products/${id}/accessories/recommended?context=${translateContext(context)}`);
	},
	getProductsForAccessory: async (id, context) => {
		const client = await getBestClient();
		return client.get(`/products/${id}/products?context=${translateContext(context)}`);
	},
	getDeliveryOptions: async (context) => {
		const client = await getBestClient();
		return client.get(`/delivery-options/${translateContext(context)}`);
	},
	getDownpaymentByPrice: async (price) => {
		const client = await getBestClient();
		return client.get(`/downpayment-service/product-from-price/${price}`, DISABLE_CACHE);
	},
	reserveClickAndCollect: async (data) => {
		const client = await getBestClient();
		return client.post(`/click-and-collect/reserve`, data, { ...DISABLE_CACHE, timeout: 60000 });
	},
	refreshItemStoreStock: async (data) => {
		const client = await getBestClient();
		return client.post(`/click-and-collect/refresh-item-store-stock`, data, { ...DISABLE_CACHE, timeout: 60000 });
	},
	getDeviceFilters: async (categories) => {
		const client = await getBestClient();
		return client.get(`/filters/devices/?categories=${categories}`);
	},
	getPreConfigBundles: async (variantParentId) => {
		const client = await getBestClient();
		return client.get(`/products/${variantParentId}/pre-config-bundles`);
	},
};
